import React from 'react';

export function Info() {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0.819641C2.23886 0.819641 0 3.06326 0 5.83055C0 8.59806 2.23886 10.8419 5 10.8419C7.76114 10.8419 10 8.59806 10 5.83055C10 3.06326 7.76136 0.819641 5 0.819641ZM5.76433 8.56678C5.76433 8.98698 5.42101 9.32756 5.00218 9.32756C4.58313 9.32756 4.24004 8.98698 4.24004 8.56678V5.23842C4.24004 4.81844 4.58313 4.47786 5.00218 4.47786C5.42101 4.47786 5.76433 4.81822 5.76433 5.23842V8.56678ZM5 3.84942C4.53752 3.84942 4.16256 3.47362 4.16256 3.01033C4.16256 2.5466 4.53752 2.17124 5 2.17124C5.46226 2.17124 5.83744 2.5466 5.83744 3.01033C5.83744 3.47362 5.46226 3.84942 5 3.84942Z"
        fill="#00C08B"
      />
    </svg>
  );
}
