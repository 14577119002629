import React from 'react';

export function BgShapeTopRight() {
  return (
    <div
      className="w-64"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        pointerEvents: 'none',
      }}
    >
      <svg
        width="100%"
        viewBox="0 0 256 177"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="256"
          height="178"
        >
          <rect y="0.00012207" width="256" height="177" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0)">
          <g opacity="0.6">
            <path
              d="M256 179C226.651 168.547 197.302 158.095 163.724 149.224C130.146 140.409 92.4024 133.234 74.9823 111.876C57.5621 90.5748 60.4024 55.0916 51.3136 25.7106C42.2249 -3.67035 21.0809 -26.8926 2.00348e-05 -50.1714L256 -50.1714L256 179Z"
              fill="#002537"
            />
            <path
              d="M256 133.177C232.521 124.815 209.041 116.396 182.217 109.333C155.329 102.271 125.097 96.564 111.148 79.5004C97.1992 62.4369 99.5345 34.0164 92.2761 10.5682C84.9546 -12.9366 68.1026 -31.5822 51.1874 -50.1714L256 -50.1713L256 133.177Z"
              fill="#004E63"
            />
            <path
              d="M256 87.3542C238.391 81.0825 220.781 74.7543 200.647 69.4997C180.513 64.1885 157.854 59.8378 147.377 47.0684C136.899 34.2425 138.667 12.9978 133.176 -4.63082C127.748 -22.2594 115.061 -36.2154 102.375 -50.1713L256 -50.1713L256 87.3542Z"
              fill="#007B81"
            />
            <path
              d="M256 41.4746C244.26 37.2935 232.521 33.1123 219.077 29.6092C205.696 26.0496 190.548 23.168 183.606 14.6362C176.6 6.10443 177.799 -8.07754 174.138 -19.8299C170.477 -31.5823 162.02 -40.8486 153.625 -50.1714L256 -50.1714L256 41.4746Z"
              fill="#00A98C"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export function BgShapeLeftBottom() {
  return (
    <div
      className="w-80"
      style={{
        position: 'absolute',
        left: 0,
        bottom: 0,
        pointerEvents: 'none',
      }}
    >
      <svg
        width="100%"
        viewBox="0 0 281 280"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4">
          <path
            d="M-0.000158587 0.536597C35.8324 14.9885 71.7341 29.4404 95.046 51.6368C118.358 73.8332 129.149 103.774 158.479 122.582C187.809 141.391 235.817 149.135 259.198 173.683C282.648 198.23 281.61 239.58 280.573 281L-0.000183105 281L-0.000158587 0.536597Z"
            fill="#002537"
          />
          <path
            d="M-0.000117713 56.6154C28.7074 68.1631 57.3458 79.7799 76.023 97.5509C94.7002 115.253 103.278 139.178 126.797 154.252C150.248 169.326 188.64 175.48 207.386 195.118C226.132 214.756 225.302 247.878 224.472 281L-0.000137329 281L-0.000117713 56.6154Z"
            fill="#004E63"
          />
          <path
            d="M2.99725e-05 112.694C21.5134 121.407 43.0268 130.05 57.0001 143.396C71.0426 156.741 77.4758 174.651 95.1154 185.922C112.686 197.262 141.463 201.895 155.505 216.623C169.617 231.352 168.994 256.176 168.371 281L1.52588e-05 281L2.99725e-05 112.694Z"
            fill="#007B81"
          />
          <path
            d="M-2.07124e-05 168.842C14.3192 174.582 28.7075 180.39 38.0461 189.241C47.3155 198.161 51.6735 210.124 63.364 217.661C75.1238 225.129 94.2852 228.24 103.693 238.059C113.101 247.878 112.686 264.474 112.201 281L-3.05176e-05 281L-2.07124e-05 168.842Z"
            fill="#00A98C"
          />
          <path
            d="M-1.41709e-05 224.921C7.19416 227.825 14.3192 230.66 19.023 235.155C23.6578 239.58 25.8022 245.527 31.682 249.33C37.5619 253.064 47.1772 254.655 51.8119 259.564C56.5158 264.474 56.3082 272.702 56.1007 281L-1.90735e-05 281L-1.41709e-05 224.921Z"
            fill="#00C08B"
          />
        </g>
      </svg>
    </div>
  );
}
